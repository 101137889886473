import gql from 'graphql-tag'

export default gql`{
  id
  email
  name
  photo
  status
  telephone
  areasExpertise
  companyName
  userType
  relationship
  paceBirth
  address
  identity 
  issue  
  nuit
  phone
  representative
  permit
  bank
  account_number
  nib
  mail
  user {
    id
    email
    name
    role
    photo
    office
  }
}`