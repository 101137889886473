import gql from 'graphql-tag'
import partnerFragment from './PartnerFragment'
export const GET_PARTNERS = gql `
  query partners {
    partners{
      ...${partnerFragment}
    }
  }
`
export const GET_PARTNER = gql `
  query getPartner($email:String) {
    partner: getPartner(email:$email){
      ...${partnerFragment}
    }
  }
`
export const UPDATED_PARTNER = gql `
subscription partner_sub{
  partner_sub{
    ...${partnerFragment}
  }
}
`