<template>
  <div>
    <v-card class="pb-0 px-4 py-12">
      <v-card-text>
        <v-container class="fill-heigt pt-0" fluid>
          <v-row class="d-flex flex-row  ">
            <v-col cols="2" class="mr-0" style="margin-top:-3%">
            <v-avatar size="50" v-if="test.creatorType !='partner'">
              <img
                :src="
                  test.user.photo
                    ? `${apiUrl}/images/user/${test.user.photo}`
                    : `/avatar.png`
                "
                alt="user"
              />
            </v-avatar>
            <v-avatar size="50" v-else>
              <img
                :src="
                  test.user.photo
                    ? `${apiUrl}/images/partner/${test.user.photo}`
                    : `/avatar.png`
                "
                alt="user"
              />
            </v-avatar>
            </v-col>
             <v-col cols="10" class="mr-0" style="margin-top:-3%">
              <v-row class="d-flex flex-row  ">
              <div class="text-h6 black--text">
                {{ getUserName(test.user.name) }}
              </div>
              <v-spacer></v-spacer>
               <v-btn
              color="primary"
              dense
              @click="$emit('initList', test.id)"
              right
              text
              style="background: #FFF4E4; color:#000;"
            >
              <v-icon left color="#F7A400">mdi-message-text-outline</v-icon>
               {{$t('TALKS')}}
            </v-btn>
              </v-row>
              <!-- <pre>{{test}}</pre> -->
              <!--<pre>{{currentUser.id}}</pre>-->
              <v-row class="py-1">
                <p class="">
                  {{ test.user.office }}
                </p>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="d-flex flex-row  py-0">
            <v-col cols="12 ">
              <h2 class="text-subtitle-1 black--text">{{$t('aboutTest')}}</h2>
            </v-col>
          </v-row>

          <v-row class="d-flex flex-row" >
            <v-col cols="12">
              <div class="text-subtitle-2">{{$t('testtitle')}}</div>
            </v-col>
            <v-col cols="12 py-0">
              <div class="text-subtitle-1 black--text text-uppercase">
                {{ test.title }}
              </div>
            </v-col>
          </v-row>

          <v-row class="d-flex flex-row  py-0">
            <v-col cols="12 py-1">
              <h4 class="text-subtitle-2">{{$t('test_objective')}}</h4>
            </v-col>
            <v-col cols="12 py-1" class=" black--text">
              <p v-html="$t(test.description)"></p>
            </v-col>
            <!-- <v-col cols="12 py-1">
              <h4 class="black--text">Tradução para inglês</h4>
            </v-col>
            <v-col cols="12 py-1" class=" black--text">
              <p v-html="$t(test.description)"></p>
            </v-col> -->
          </v-row>

          <v-row class="d-flex flex-row  py-0">
            <v-col cols="12">
              <h4 class="text-subtitle-2">{{$t('Intendedfor')}}</h4>
            </v-col>
            <v-col cols="12">
              <div class="px-0 mt-0">
                <!-- <pre>
                  {{test.areas}}
                </pre> -->
                <template
                 v-for="(item, index) in test.areas"
                >
                <v-chip
                  class="ma-2"
                  :key="index"
                  v-if="test.areas[index]!='all'"
                >
                  {{ $t(test.areas[index]) }}
                </v-chip>
                </template>
              </div>
            </v-col>
          </v-row>

          <v-row class="d-flex flex-row  py-0">
            <v-col cols="12">
              <h4 class="text-subtitle-2">{{$t('Expectedresults')}}</h4>
            </v-col>
            <v-col cols="12">
              <p
                class=" black--text"
                v-for="(item, index) in test.questions"
                :key="index"
              >
                {{ item.objective }}
              </p>
            </v-col>
          </v-row>
        </v-container>

        <v-row class="d-flex flex-row justify-end align-center mt-5 px-6 py-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mx-2"
            @click="$emit('close')"
            text
          >
            {{$t('close')}}
          </v-btn>
          <v-btn color="primary" type="submit" @click="$emit('start')">
            <v-icon left>mdi-send</v-icon>
            {{$t('inittest')}}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { API_URL } from "@/api";
import { mapGetters } from "vuex";
export default {
  name: "PreviewDescription",
  components: {},
  props: {
    test: Object,
  },
  data: () => ({
    apiUrl: API_URL,
  }),
 computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    getUserName(name) {
      name = name.split(" ");
      return name[0]+' '+name[name.length -1]
    },
  },
};
</script>
