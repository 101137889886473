<template>
  <v-menu open-on-hover bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
        tile
      >
        <form ref="myForm">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <input
                class="input-file"
                id="my-file"
                type="file"
                v-bind="attrs"
                v-on="on"
                title=""
                multiple
                @change="uploadFile"
                ref="file"
              />
            </template>
            <span>{{ $t("attach_files") }}</span>
          </v-tooltip>
        </form>
        <v-icon small color="#fff">mdi-paperclip</v-icon>
        <!-- {{ files.length }} -->
        <span class="text-caption white--text text-capitalize "
          v-if="files!=undefined">{{ $t("file") }} {{ files.length ? "["+files.length+"]" : "" }} 
        </span>
        <span class="text-caption white--text text-capitalize "
          v-else>{{ $t("file") }} {{ files_.length ? "["+files_.length+"]" : "" }} 
        </span>
      </v-btn>
      <WhirelabSnackbar
        :snackbar="snackbar"
        :timeout="timeout"
        :text="snackbar_test"
        :close="closeSnackbar"
        :color="'red'"
      />
    </template>

    <!-- <v-card>
        <v-list>
          <v-list-item v-for="(filename, index) in files" :key="index">
            <v-list-item-avatar size="20">
              <v-icon x-small class="grey lighten-1" dark> mdi-file </v-icon>
            </v-list-item-avatar>
  
            <v-list-item-content>
              <v-list-item-title>{{ filename }}</v-list-item-title>
            </v-list-item-content>
  
            <v-list-item-action>
              <v-btn x-small icon @click="removeTrainingFile(index)">
                <v-icon color="error">mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card> -->
  </v-menu>
</template>
  
  <script>
import WhirelabSnackbar from "./../../../components/WhirelabSnackbar.vue";
export default {
  name: "UploadFile",
  components: {
    WhirelabSnackbar,
  },
  props: ["files","pos"],

  data: () => ({
    timeout: 900,
    snackbar: false,
    snackbar_test: "",
    files_:[]
  }),

  methods: {
    closeSnackbar() {
      this.snackbar = false;
      // this.$router.push({
      //   path: `/entity/trainings/list`,
      // });
    },
    uploadFile() {
      let files = [];
      for (let index = 0; index < this.$refs.file.files.length; index++) {
        const element = this.$refs.file.files[index];
        // console.log("file", element.size);
        if (element.size >= 2000000) {
          this.timeout = 2000;
          this.snackbar = true;
          this.snackbar_test = this.$t("The_document_cannot_exceed_2_MB");
          setTimeout(() => {
            this.snackbar = false;
            this.resetForm();
          }, this.timeout);
        } else {
          files.push(element);
        }
      }
      this.files_ = files;
      this.$emit("upload", files,this.pos);
    },

    resetForm() {
      this.$refs.myForm.reset();
      this.$refs.file.value = ""; // Resetting the value property for input file
    },
    removeTrainingFile(index) {
      // this.trainingFiles.splice(index, 1);
      this.$emit("remove", index);
    },
  },
};
</script>
  
  <style scoped>
.input-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 225px;
  opacity: 0;
  /* padding: 14px 0; */
  cursor: pointer;
}
@import "./../styles/input-file.css";
@import "./../styles/input-time.css";
</style>