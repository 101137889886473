<template>
  <div class="fill-heigt pt-0" fluid>
    <!-- <pre>
   >>>   {{ this.currentUser }}
    </pre> -->
    <v-alert
      dense
      text
      type="error"
      v-if="partner.nuit === null && partner.address === null"
    >
      {{ $t("partner_alert") }}
    </v-alert>
    <v-row class="d-flex flex-row py-1">
      <div class="text-left subtitle px-3" style="margin-top: 2%">
        {{ $t("test_library") }}
      </div>
      <v-spacer></v-spacer>
      <v-col cols="3" md="2" class="text-right px-0 mt-1">
        <!-- <v-btn icon color="primary" @click="infoDialog = true">
          <v-icon>info</v-icon>
        </v-btn> -->
        <test-filter @apply="applyTestFilter" @clean="applyTestFilter()" />
      </v-col>
      <v-col cols="9" md="3">
        <div class="text-right">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('searchText')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-3" style="margin-top: -7%"></v-divider>

    <v-row class="py-0 px-2">
      <v-col cols="12" md="9">
        <v-row class="d-flex flex-row align-center py-4">
          <v-radio-group
            v-model="filter.type"
            class="mt-0"
            row
            @change="applyFilter()"
          >
            <v-radio :label="$t('technician')" value="technician"></v-radio>
            <v-radio
              :label="$t('psychological')"
              value="psychological"
            ></v-radio>
          </v-radio-group>
          <v-checkbox
            v-model="filter.user"
            :label="$t('myteste')"
            :value="currentUser.id"
            class="mt-0"
            @change="applyFilter()"
          ></v-checkbox>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" class="text-right justify-end">
        <div class="d-flex justify-end">
          <v-col cols="12" md="3" class="text-right justify-end">
            <div class="d-flex justify-end">
              <v-btn dense color="primary" @click.prevent="dialogTestAdd()">
                <v-icon left>mdi-plus</v-icon>{{ $t("createnew") }}
              </v-btn>
            </div>
          </v-col>
        </div>
      </v-col>
    </v-row>

    <infinite-scroll
      :items="filteredItems"
      :totalPages="totalPages"
      @refetch="fetch"
    >
      <template v-slot:item="{ item }">
        <v-skeleton-loader
          v-if="$apollo.loading"
          elevation="2"
          v-bind="filteredItems"
          type="image, article"
        ></v-skeleton-loader>
        <test-card
          v-else
          :test="item"
          @delete="openConfirmeDialog"
          @edit="edit"
          @preview="showPreviewDialog"
        />
      </template>
    </infinite-scroll>

    <add-test-dialog />
    <edit-test-dialog />
    <preview-test-dialog
      :dialog="previewDialog"
      :test="test"
      @close="previewDialog = false"
    />
    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="$t('testQ')"
      @yes="deleteTest"
      @no="confirm = false"
    />
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
    <alert-dialog
      :dialog="dialogAlert"
      :message="$t('partner_alert_dialog')"
      @close="closeAlert()"
    />
  </div>
</template>

<script>
import { DELETE_TEST_MUTATION } from "./../graphql/Mutation.service";
import { GET_TESTS_PARTNER_QUERY } from "./../graphql/Query.service";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import TestCard from "./../components/TestCard.vue";
import TestFilter from "./../components/Filter.vue";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import dialogMixins from "@/mixins/dialog";
import Test from "@/models/Test";
import AddTestDialog from "../components/AddTestDialog.vue";
const EditTestDialog = () => import("../components/EditTestDialog.vue");
import PreviewTestDialog from "./../components/PreviewTestDialog.vue";
import InfiniteScroll from "./../components/InfiniteScroll.vue";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import { GET_PARTNER, UPDATED_PARTNER } from "../../profile/graphql/Query";
import AlertDialog from "../../../../entity/components/AlertDialog.vue";
export default {
  name: "Test",
  components: {
    TestCard,
    TestFilter,
    AlertDialog,
    ConfirmDialog,
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
    AddTestDialog,
    EditTestDialog,
    PreviewTestDialog,
    InfiniteScroll,
  },
  mixins: [dialogMixins, replaceSpecialCharsMixins],

  data: () => ({
    appliedFilter: true,
    dialog: false,
    filter: {
      areas: undefined,
      category: "",
      entity: undefined,
      limit: 8,
      page: 1,
      positions: undefined,
      type: "",
      user: undefined,
    },
    previewDialog: false,
    test: new Test(),
    tests: [],
    textSearch: undefined,
    totalPages: 1,
    partner: [],
    dialogAlert: false,
  }),
  apollo: {
    tests: {
      query: GET_TESTS_PARTNER_QUERY,
      variables() {
        return { filterTest: this.filter };
      },
    },
    partner: {
      query: GET_PARTNER,
      fetchPolicy: "no-cache",
      variables() {
        return { email: this.currentUser.email };
      },
    },
    $subscribe: {
      message: {
        query: UPDATED_PARTNER,
        result({ data }) {
          if (data.partner_sub.user.id === this.currentUser.id) {
            // console.log("real time update", data.partner_sub);
            this.partner = data.partner_sub;
          }
        },
      },
    },
  },
  watch: {
    tests: function (val) {
     
      if (this.appliedFilter) {
        this.setTestsState(val.tests);
        this.appliedFilter = false;
      } else {
        // this.setTestsScrolled(val.tests);
        let result = val
        result = result.tests.filter(test=>test.user.id !== this.currentUser.id)
        result = result.tests.filter(test => test.isDraft === false)
        this.setTestsScrolled(result);
      }
      this.totalPages = val.totalPages;
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getTests: "test/getTests",
      setTestsScrolled: "test/setTestsScrolled",
    }),
    filteredItems() {
      // console.log("USER", this.currentUser.id);
      let resultItems = [...this.getTests];
      if (this.currentUser) {
        let array_1 = resultItems.filter((test) => {
          if (test.user) {
            if (test.user.id === this.currentUser.id && test.reason != null) {
              return test;
            }
          }
        });
        let array_2 = resultItems.filter(
          (test) =>
            test.reason === null &&
            (test.publish === true || test.publish === null)
        );
        let array_3 = resultItems.filter(
          (test) =>{
            if (test.user) {
              if(test.user.id === this.currentUser.id && test.reason === null && test.publish === false){
                return test
              }
            }
          }
            
        );
        resultItems = array_3.concat(array_1).concat(array_2);
      }
      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.title).includes(text)
        );
      }
      return resultItems;
    },
  },

  mounted() {
    console.log("filteredItems", this.tests);
    // this.applyTestFilter()
  },
  created() {
    this.applyFilter();
  },
  methods: {
    ...mapActions({
      removeTestState: "test/removeTest",
      setTestsState: "test/setTests",
    }),
    async applyFilter() {
      this.appliedFilter = true;
      this.fetch(1);
    },
    applyTestFilter(filter) {
      this.filter = { ...filter };
      this.applyFilter();
    },
    cleanFilter() {
      this.filter = {
        areas: undefined,
        category: undefined,
        positions: undefined,
      };
    },
    async deleteTest(id) {
      this.confirm = false;
      this.removeId = undefined;
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_TEST_MUTATION,
          variables: { id },
        });
        this.removeTestState(id);
        this.success = this.$t("test_remove");
        this.showSuccess = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    closeAlert() {
      this.dialogAlert = false;
      this.$router.push("/partner/profile");
    },
    dialogTestAdd() {
      if (this.partner.nuit === null && this.partner.address === null) {
        this.dialogAlert = true;
      } else {
        this.filter.type = "";
        // eslint-disable-next-line no-undef
        Fire.$emit("clearTestFilter");
        // eslint-disable-next-line no-undef
        Fire.$emit("dialogTestAdd");
      }
    },
    edit(test) {
      // eslint-disable-next-line no-undef
      Fire.$emit("dialogTestAddEdit", test);
    },
    async fetch(page) {
      if (page > this.totalPages) {
        return;
      }
      this.filter.page = page;
      await this.$apollo.queries.tests.refetch();
    },
    openConfirmeDialog(id) {
      this.removeId = id;
      this.confirm = true;
    },
    showPreviewDialog(test) {
      this.test = test;
      this.previewDialog = true;
    },
  },
};
</script>
