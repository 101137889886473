<template>
  <v-dialog v-model="dialog" persistent max-width="604">
    <preview-description
      :test="test"
      @close="closeDialog()"
      @initList="initList"
      @start="setStart()"
      v-if="status === 'stop'"
    />
    <quiz
      :start="start"
      :test="test"
      @close="closeDialog()"
      v-else-if="status === 'start'"
    />
    <chatList
      v-if="showList"
      @initList="initList"
      @initChat="initChat"
      @close="close()"
      :paramMessage="paramMessage"
    />
    <chatMessage
      v-if="showChat"
      :paramMessage="paramMessage"
      @initChat="initChat"
      @close="close"
    />
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import PreviewDescription from "./PreviewDescription.vue";
import Quiz from "./Quiz.vue";
import chatList from "./../../chat/components/chalList";
import chatMessage from "./../../chat/components/chatMessage";
import { mapGetters } from "vuex";
export default {
  name: "PreviewTest",
  components: { PreviewDescription, Quiz, chatList, chatMessage },
  props: {
    dialog: Boolean,
    test: Object,
  },
  data: () => ({
    apiUrl: API_URL,
    status: "stop",
    start: false,
    showChat: false,
    showList: false,
    paramMessage: [],
  }),
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  created() {
    this.status = "stop";
    this.start = false;
  },
  methods: {
    closeDialog() {
      this.status = "stop";
      this.start = false;
      this.showChat = false;
      this.$emit("close");
      this.close();
    },
    setStart() {
      this.status = "start";
      this.start = true;
    },
    initChat(valor) {
      console.log("passando", valor);
      this.paramMessage = valor;
      this.showChat = !this.showChat;
      this.showList = !this.showList;
      this.$emit("initChat");
    },
    initList() {
      this.paramMessage = {
        testID: this.test.id,
      };
      this.showList = !this.showList;
      this.$emit("initList");
    },
    close() {
      this.showChat = false;
      this.showList = false;
    },
  },
  mounted() {
    // console.log("sala", this.test);
  },
};
</script>
