import gql from 'graphql-tag'
import testFagment from './TestFragment'

export const CREATE_TEST_MUTATION = gql`
  mutation CreateTest($testInput: TestInput) {
    createTest(testInput: $testInput) {
      ...${testFagment}
    }
  }
`

export const UPDATE_TEST_MUTATION = gql`
  mutation UpdateTest($testInput: TestInput) {
    updateTest(testInput: $testInput) {
      ...${testFagment}
    }
  }
`

export const DELETE_TEST_MUTATION = gql`
  mutation DeleteTest($id: ID!) {
    deleteTest(id: $id) {
      id
    }
  }
`