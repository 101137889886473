<template>
  <v-card class="pb-5 px-5">
    <v-card-title class="justify-center">
      <span class="headline">{{ $t(test.area) }}</span>
    </v-card-title>
    <v-card-text>
      <!-- <pre>{{ test.questions[position] }}</pre> -->
      <v-row class="pt-1 justify-center" no-gutters>
        <v-col
          v-show="test.questions[position].picture"
          cols="12"
          md="12"
          class="pb-5 text-center justify-center"
        >
          <v-img
            :src="`${apiUrl}/images/test/${test.questions[position].picture}`"
            aspect-ratio="2"
            contain
          ></v-img>
        </v-col>
        <v-col cols="12" md="12" lg="12" xlg="12">
          <div class="text-justify">
            <v-textarea
              :value="
                test.questions[position].question +
                ' (' +
                test.questions[position].percentage +
                '%)'
              "
              auto-grow
              dense
              hide-details
              flat
              readonly
              solo
            />
          <!-- <span class="title black--text"
            >{{ test.questions[position].question }}({{
              test.questions[position].percentage
            }}%)</span
          > -->
        </div>
          <div class="text-center">
          <v-btn small dense color="error" v-if="test.questions[position].file">
            <v-icon class="ma-1" small>mdi-cloud-download</v-icon>
            &nbsp;&nbsp;<a :href="`${apiUrl}/files/test/${test.questions[position].file}`" target="_blank" class="text-decoration-none white--text">{{$t('download_file')}}</a></v-btn
          ></div>
        </v-col>
        <v-col cols="12" md="12" lg="12" xlg="12" class="text-center pa-2">
          <v-row
            no-gutters
            v-if="test.questions[position].alternatives[0].answer"
          >
            <v-col
              cols="12"
              md="12"
              class="pa-1"
              v-for="(alternative, index) in questions[position].alternatives"
              :key="index"
            > 

            <v-card
                class="mx-auto"
                max-width="500"
                flat
                fluid
                style="border: 1px solid #f7a400;  border-radius: 5px;"
                :style="{
                  background: alternative.response ? '#f7a400' : '',
                  color: alternative.response ? '#fff' : '#000',
                }"
                @click="selectAnswer(index)"
              >
                <v-card-text style="padding-top: -20px">
                  <div
                    class="flex-grow-1 text-center"
                    :style="{ color: alternative.response ? '#fff' : '#000' }"
                  >
                    {{ alternative.answer }}
                  </div>
                </v-card-text>
              </v-card>
            <!-- <v-btn
                :color="alternative.response ? 'primary' : 'primary'"
                dark
                tile
                outlined
                block
                class="align-center py-3 "
                @click="selectAnswer(index)"
                :style="{ 'background': alternative.response ? '#f7a400':'' , 'color': alternative.response ? '#fff':'#fff'}"
              >
                <div class="flex-grow-1 text-center" :style="{'color': alternative.response ? '#fff':'#000'}" v-html=" alternative.answer.length> 55 ? alternative.answer.substring(0, 55)+'-<br/>'+alternative.answer.substring(55, alternative.answer.length):alternative.answer.substring(0, alternative.answer.length)">
            
                 </div>
              </v-btn> -->
            </v-col>
          </v-row>
          <v-textarea
            v-else
            v-model="test.questions[position].answer"
            rows="3"
            
            :label="$t('answer')"
            class="pt-5"
          ></v-textarea>
        </v-col>
        <v-row class="pt-2 justify-center px-3" no-gutters>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-expansion-panels class="mx-auto " >
            <v-expansion-panel style="box-shadow: 0px 0px 0px 0px">
              <v-expansion-panel-header class="primary--text"
                >{{$t('test_review_title')}}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-col
                  cols="12"
                  md="12"
                  class="text-left"
                  v-if="test.questions[position].objective"
                >
                  <p class="subtitle-2 black--text">
                    <span class="font-weight-black"
                      >{{$t('test_review_subtitle')}}</span
                    ><br />
                    {{ test.questions[position].objective }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="text-left"
                  v-if="test.questions[position].implication"
                >
                  <p class="subtitle-2 black--text">
                    <span class="font-weight-black"
                      >{{$t('test_review_subtitle_2')}}</span
                    ><br />
                    {{ test.questions[position].implication }}
                  </p>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters>
        <v-col cols="12" class="px-6">
          <v-progress-linear
            v-model="time"
            :buffer-value="bufferValue"
          ></v-progress-linear>
        </v-col>
        <v-col cols="12" class="px-6">
          <v-btn
            v-if="position < questions.length - 1"
            block
            color="primary"
            dense
            @click="nextQuestion()"
            >{{$t('next')}} {{ position + 1 }}/{{ questions.length }}</v-btn
          >
          <v-btn
            v-else
            block
            color="primary"
            dense
            @click="(dialog = false), nextQuestion()"
            >{{$t('finish')}} {{ position + 1 }}/{{ questions.length }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { API_URL } from "@/api";
export default {
  name: "Quiz",
  props: {
    test: Object,
    start: Boolean,
  },
  data: () => ({
    apiUrl: API_URL,
    bufferValue: 100,
    interval: 0,
    position: 0,
    questions: [
      {
        question: "",
        alternatives: [
          {
            answer: "",
            value: false,
            response: false,
          },
        ],
        totalCorrect: 0,
        status: false,
        picture: undefined,
      },
    ],
    time: 0,
  }),
  watch: {
    time(val) {
      if (val < 100) return;

      if (this.position >= this.test.questions.length - 1) {
        clearInterval(this.interval);
      }
      this.nextQuestion();
    },
    start(val) {
      if (val) {
        this.startBuffer();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    this.questions = this.test.questions.map((q) => ({
      ...q,
      alternatives: q.alternatives.map((a) => ({ ...a, response: false })),
    }));
    this.startBuffer();
  },
  methods: {
    getDuraction(test) {
      const t = test.duraction;

      let ms =
        Number(t.split(":")[0]) * 60 * 60 * 1000 +
        Number(t.split(":")[1]) * 60 * 1000;

      return ms / test.questions.length / 100;
    },
    nextQuestion() {
      if (this.position < this.test.questions.length - 1) {
        this.position += 1;
      } else {
        clearInterval(this.interval);
        this.$emit("close");
      }
      this.time = 0;
    },
    selectAnswer(pos) {
      let value = this.questions[this.position].alternatives[pos].response;
      if (this.questions[this.position].totalCorrect == 1) {
        for (
          let index = 0;
          index < this.questions[this.position].alternatives.length;
          index++
        ) {
          this.questions[this.position].alternatives[index].response = false;
        }
      }
      this.questions[this.position].alternatives[pos].response = value
        ? false
        : true;
    },
    startBuffer() {
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.time += 1;
      }, this.getDuraction(this.test));
    },
  },
};
</script>
